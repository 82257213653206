<template>
  <VDialog
    v-model="dialog"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    width="1200"
  >
    <VCard>
      <VCardTitle class="headline">
        {{ $t('personal_data_processing_policy.one') }}
        <VSpacer />
      </VCardTitle>
      <VDivider />
      <VCardText class="black--text pb-8">
        <template v-if="!branding?.privacy_policy">
          <section>
            <h2>1.&ensp;Общие положения</h2>
            <p>1.1.&ensp;Настоящее Положение является официальным документом Администрации сайта, на&nbsp;котором оно размещено и&nbsp;определяет порядок обработки и&nbsp;защиты информации о&nbsp;физических лицах, пользующихся услугами интернет-сайта (далее&nbsp;— <em>Сайт</em>) и&nbsp;его сервисов (далее&nbsp;— <em>Пользователи</em>).</p>
            <p>1.2.&ensp;Отношения, связанные со&nbsp;сбором, хранением, распространением и&nbsp;защитой информации о&nbsp;пользователях <em>Сайта</em>, регулируются настоящим Положением, иными официальными документами Администрации <em>Сайта</em> и&nbsp;действующим законодательством Российской Федерации.</p>
            <p>1.3.&ensp;Регистрируясь, отправляя сообщения, заявки, лиды, иные послания с&nbsp;помощью средств и&nbsp;форм связи на&nbsp;<em>Сайте</em>, <em>Пользователь</em> выражает свое согласие с&nbsp;условиями Положения. В&nbsp;случае несогласия <em>Пользователя</em> с&nbsp;условиями Положения использование <em>Сайта</em> и&nbsp;его сервисов должно быть немедленно прекращено. Ответственность за&nbsp;это несет сам <em>Пользователь</em>.</p>
            <p>1.4.&ensp;Администрация <em>Сайта</em> не&nbsp;проверяет достоверность получаемой (собираемой) информации о&nbsp;<em>Пользователях</em>, за&nbsp;исключением случаев, когда такая проверка необходима в&nbsp;целях исполнения Администрацией <em>Сайта</em> обязательств перед <em>Пользователем</em>.</p>
          </section>

          <section>
            <h2>2.&ensp;Условия и&nbsp;цели обработки персональных данных</h2>
            <p>2.1.&ensp;Администрация <em>Сайта</em> осуществляет обработку персональных данных пользователя в&nbsp;целях исполнения своих обязательств между Администрацией <em>Сайта</em> и&nbsp;<em>Пользователем</em> в&nbsp;рамках предоставления информации о&nbsp;деятельности и&nbsp;работе структурных подразделений владельцев <em>Сайта</em>. В&nbsp;силу статьи 6 Федерального закона от&nbsp;27.07.2006 №&nbsp;152-ФЗ «О&nbsp;персональных данных» отдельное согласие пользователя на&nbsp;обработку его персональных данных не&nbsp;требуется. В&nbsp;силу пп.&nbsp;2 п.&nbsp;2 статьи 22 указанного закона Администрация <em>Сайта</em> вправе осуществлять обработку персональных данных без уведомления уполномоченного органа по&nbsp;защите прав субъектов персональных данных.</p>
            <p>2.2.&ensp;Цели обработки персональных данных включают в&nbsp;себя: получение <em>Пользователем</em> информации, рассылок, документов и&nbsp;материалов, в&nbsp;том числе рекламного характера, обработка заказов <em>Пользователя</em>, направленных на&nbsp;получение товаров и&nbsp;услуг, консультационную поддержку <em>Пользователя</em>.</p>
          </section>

          <section>
            <h2>3.&ensp;Порядок ввода в&nbsp;действие и&nbsp;изменения Положения</h2>
            <p>3.1.&ensp;Положение вступает в&nbsp;силу с&nbsp;момента его размещения на&nbsp;<em>Сайте</em> и&nbsp;действует бессрочно, до&nbsp;замены его новым Положением.</p>
            <p>3.2.&ensp;Действующая редакция Положения, являющимся публичным документом, доступна любому пользователю сети Интернет.</p>
            <p>3.3.&ensp;Администрация <em>Сайта</em> вправе вносить изменения в&nbsp;Положение. При внесении изменений в&nbsp;Положение уведомляет об&nbsp;этом пользователей путем размещения новой редакции на&nbsp;<em>Сайте</em> по&nbsp;постоянному адресу. Предыдущие редакции Положения при этом утрачивают силу.</p>
          </section>

          <section>
            <h2>4.&ensp;Цели обработки информации</h2>
            <p>4.1.&ensp;Администрация <em>Сайта</em> осуществляет обработку информации о&nbsp;<em>Пользователях</em>, в&nbsp;том числе их персональных данных, в&nbsp;целях выполнения обязательств между Администрацией <em>Сайта</em> и&nbsp;<em>Пользователем</em> в&nbsp;рамках предоставления информации о&nbsp;деятельности и&nbsp;работе структурных подразделений владельцев <em>Сайта</em>.</p>
          </section>

          <section>
            <h2>5.&ensp;Состав персональных данных</h2>
            <p>5.1.&ensp;Персональные данные предоставляются <em>Пользователем</em> добровольно, означают согласие на&nbsp;их обработку Администрацией <em>Сайта</em> и&nbsp;включают в&nbsp;себя:</p>
            <ul>
              <li>предоставляемые <em>Пользователями</em> минимально необходимые данные для связи: имя (возможно использование вымышленного), номер мобильного телефона и/или адрес электронной почты;</li>
              <li>иные данные (в&nbsp;том числе пол, возраст, дата рождения, адрес и&nbsp;т.&nbsp;д.) предоставляются <em>Пользователем</em> по&nbsp;желанию и&nbsp;в&nbsp;случае необходимости таких данных для связи с&nbsp;пользователем и&nbsp;осуществлением действий, связанных с&nbsp;предоставлением услуг или доставкой товаров <em>Пользователю</em>.</li>
            </ul>
            <p>5.2.&ensp;Иная информация о&nbsp;<em>Пользователях</em>, обрабатываемая Администрацией <em>Сайта</em>.</p>
            <p>Администрация <em>Сайта</em> обрабатывает также иную информацию о&nbsp;<em>Пользователях</em>, которая включает в&nbsp;себя:</p>
            <ul>
              <li>стандартные данные, автоматически получаемые сервером при доступе к&nbsp;<em>Сайту</em> и&nbsp;последующих действиях <em>Пользователя</em> (IP-адрес хоста, вид операционной системы пользователя, страницы <em>Сайта</em>, посещаемые пользователем);</li>
              <li>информация, автоматически получаемая при доступе к&nbsp;<em>Сайту</em> с&nbsp;использованием закладок (cookies);</li>
              <li>информация, полученная в&nbsp;результате действий <em>Пользователя</em> на&nbsp;<em>Сайте</em>;</li>
              <li>информация, полученная в&nbsp;результате действий других пользователей на&nbsp;<em>Сайте</em>;</li>
              <li>информация, необходимая для идентификации <em>Пользователя</em> для доступа к&nbsp;сервисам сайта.</li>
            </ul>
          </section>

          <section>
            <h2>6.&ensp;Обработка информации о&nbsp;пользователях</h2>
            <section>
              <h3>6.1.&ensp;Обработка персональных данных осуществляется на&nbsp;основе следующих принципов:</h3>
              <ul>
                <li>законности целей и&nbsp;способов обработки персональных данных;</li>
                <li>добросовестности;</li>
                <li>соответствия целей обработки персональных данных целям, заранее определенным и&nbsp;заявленным при сборе персональных данных, а&nbsp;также полномочиям Администрации <em>Сайта</em>;</li>
                <li>соответствия объема и&nbsp;характера обрабатываемых персональных данных, способов обработки персональных данных целям обработки персональных данных;</li>
              </ul>
            </section>
            <section>
              <h3>6.2.&ensp;Сбор персональных данных</h3>
              <p>6.2.1.&ensp;Сбор персональных данных <em>Пользователя</em> осуществляется на&nbsp;<em>Сайте</em> при внесении их пользователем по&nbsp;своей инициативе на&nbsp;момент обращения к&nbsp;Администрации сайта либо к&nbsp;сайту, согласно настроек <em>Пользователя</em>.</p>
              <p>6.2.2.&ensp;Имя, адрес электронной почты и/или телефон предоставляются <em>Пользователем</em> для осуществления обратной связи и&nbsp;для стандартной работы на&nbsp;<em>Сайте</em> не&nbsp;требуются.</p>
              <p>6.2.3.&ensp;Остальные Персональные данные, предоставляются <em>Пользователем</em> дополнительно по&nbsp;собственной инициативе с&nbsp;использованием соответствующих разделов и&nbsp;ресурсов <em>Сайта</em>.</p>
            </section>
            <section>
              <h3>6.3.&ensp;Хранение и&nbsp;использование персональных данных</h3>
              <p>6.3.1.&ensp;Персональные данные <em>Пользователей</em> хранятся исключительно на&nbsp;электронных носителях и&nbsp;обрабатываются с&nbsp;использованием автоматизированных систем, за&nbsp;исключением случаев, когда неавтоматизированная обработка персональных данных необходима в&nbsp;связи с&nbsp;исполнением требований законодательства.</p>
            </section>
            <section>
              <h3>6.4.&ensp;Передача персональных данных</h3>
              <p>6.4.1.&ensp;Персональные данные <em>Пользователей</em> не&nbsp;передаются каким-либо лицам, за&nbsp;исключением случаев, прямо предусмотренных настоящим Положением.</p>
              <p>6.4.2.&ensp;Приложения, используемые <em>Пользователями</em> на&nbsp;<em>Сайте</em>, размещаются и&nbsp;поддерживаются третьими лицами (разработчиками), которые действуют независимо от&nbsp;Администрации <em>Сайта</em> и&nbsp;не&nbsp;выступают от&nbsp;имени или по&nbsp;поручению Администрации <em>Сайта</em>. <em>Пользователи</em> обязаны самостоятельно ознакомиться с&nbsp;правилами оказания услуг и&nbsp;политикой защиты персональных данных таких третьих лиц (разработчиков) до&nbsp;начала использования соответствующих приложений.</p>
              <p>6.4.3.&ensp;Предоставление персональных данных <em>Пользователей</em> по&nbsp;запросу государственных органов (органов местного самоуправления) осуществляется в&nbsp;порядке, предусмотренном законодательством.</p>
            </section>
            <section>
              <h3>6.5.&ensp;Уничтожение персональных данных</h3>
              <p>6.5.1.&ensp;Персональные данные пользователя уничтожаются по&nbsp;письменной просьбе <em>Пользователя</em>. Просьба должна содержат идентификационные данные, которые прямо указывает на&nbsp;принадлежность информации данному <em>Пользователю</em>.</p>
            </section>
          </section>

          <section>
            <h2>7.&ensp;Меры по&nbsp;защите информации о&nbsp;<em>Пользователях</em></h2>
            <p>7.1.&ensp;Администрация <em>Сайта</em> принимает технические и&nbsp;организационно-правовые меры в&nbsp;целях обеспечения защиты персональных данных <em>Пользователя</em> от&nbsp;неправомерного или случайного доступа к&nbsp;ним, уничтожения, изменения, блокирования, копирования, распространения, а&nbsp;также от&nbsp;иных неправомерных действий.</p>
          </section>

          <section>
            <h2>8.&ensp;Ограничение действия Правил</h2>
            <p>8.1.&ensp;Действие настоящих Правил не&nbsp;распространяется на&nbsp;действия и&nbsp;интернет-ресурсов третьих лиц.</p>
            <p>8.2.&ensp;Администрация <em>Сайта</em> не&nbsp;несет ответственности за&nbsp;действия третьих лиц, получивших в&nbsp;результате использования Интернета или Услуг <em>Сайта</em> доступ к&nbsp;информации о&nbsp;<em>Пользователе</em> и&nbsp;за&nbsp;последствия использования информации, которая, в&nbsp;силу природы <em>Сайта</em>, доступна любому пользователю сети Интернет.</p>
            <p>8.3.&ensp;Администрация <em>Сайта</em> рекомендует <em>Пользователям</em> ответственно подходить к&nbsp;решению вопроса об&nbsp;объеме информации о&nbsp;себе, передаваемой с&nbsp;<em>Сайта</em>.</p>
          </section>
        </template>
        <div
          v-else
          class="rte-content"
          v-html="branding.privacy_policy"
        />

        <hr>
        <div class="pt-8">
          <a
            href="http://www.consultant.ru/document/cons_doc_LAW_61801/"
            target="_blank"
          >Федеральный закон от&nbsp;27.07.2006 №&nbsp;152-ФЗ «О&nbsp;персональных данных»</a>.
        </div>
      </VCardText>
      <VDivider />
      <VCardActions v-if="account.id">
        <template v-if="!account?.profile.privacy_policy_accepted">
          <form
            @submit.prevent="accept"
          >
            <VBtn
              type="submit"
              color="primary"
            >
              Принять
            </VBtn>

            <VBtn
              v-if="!branding.require_privacy_policy_acceptance"
              @click="cancel"
            >
              Вернуться
            </VBtn>
          </form>
        </template>

        <template v-else>
          <VChip
            class="mr-2"
            variant="flat"
            color="success"
            label
          >
            {{ ('Политика принята ' + formatDate(account.profile.privacy_policy_accepted_at)).toUpperCase() }}
          </VChip>
          <VBtn @click="cancel">
            Вернуться
          </VBtn>
        </template>
        <VSpacer />
      </VCardActions>
      <VCardActions v-else>
        <VBtn @click="cancel">
          Вернуться
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { mapGetters } from 'vuex'
import * as getters from '@/store/getters/types'
import { acceptPrivacyPolicy } from '@/api/api'
import { UDate } from '@/utils/date'

export default {
  name: 'ThePrivacyPolicy',

  data () {
    return {
      dialog: true,
    }
  },

  computed: {
    ...mapGetters({
      vendor: getters.VENDOR,
      account: getters.ACCOUNT
    }),

    branding () {
      return this.vendor?.branding || {}
    }
  },

  methods: {
    formatDate: UDate.formatDate,

    async accept () {
      try {
        const data = new FormData()
        data.append('privacy_policy_accepted', 1)
        await acceptPrivacyPolicy(data)
      } finally {
        this.cancel()
      }
    },

    cancel () {
      this.$router.push('/')
    }
  }
}
</script>
